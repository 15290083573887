import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl";

// markup
const Mapbox = () => {
  mapboxgl.accessToken =
    "pk.eyJ1Ijoic2ViYXN0aWVuZmdpbCIsImEiOiJja3N5anMxeG8wODlpMnVtb2J5cWdheHQ4In0.MP8yX6mEd2pTJ3JXLaRMjA";
  const mapContainer = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);
  const [lng, setLng] = useState(-1.61);
  const [lat, setLat] = useState(49.43);
  const [zoom, setZoom] = useState(8.57);
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/sebastienfgil/cksyjtdtq5ueh17qncydq9po7",
      center: [lng, lat],
      zoom: zoom,
      scrollZoom: false,
      zoomControl: true,
    });
    // marker = new mapboxgl.Marker()
    //     .setLngLat([49.310, 8.57])
    //     .addTo(map);
  });

  return (
    <div>
      <div ref={mapContainer} className="map-container" />
    </div>
  );
};

export default Mapbox;
