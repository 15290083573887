import * as React from "react";
import { Helmet } from "react-helmet";

import useSticky from "../hooks/useSticky";
import Titre from "../components/titre";
import Navigation from "../components/navigation";
import Evenements from "../components/evenements";
import Mapbox from "../components/mapbox";

import JeSemeImg from "../images/je-seme.jpg";
import { FaFacebook, FaInstagram, FaRegEnvelope } from "react-icons/fa";

import "../styles/styles.css";

// markup
const IndexPage = () => {
  const { isSticky, element } = useSticky();
  return (
    <div>
      <Helmet>
        <title>Accueil - Sénevé</title>
      </Helmet>
      <header className="flou">
        <Titre></Titre>
        <Navigation sticky={isSticky}></Navigation>
      </header>
      <main ref={element}>
        <section id="presentation">
          <div className="flou">
            <h2 style={{ display: "none" }}>Présentation</h2>
            <p>
              Sénevé est une{" "}
              <em>
                <b>église chrétienne</b>
              </em>{" "}
              fondée en 2019 à{" "}
              <em>
                <b>Cherbourg-en-Cotentin</b>
              </em>
              .
            </p>
          </div>
        </section>
        <section className="yellow section" id="evenements">
          <h2>Événements</h2>
          {/* <p>Retrouvez bientôt nos événements dans l'Unité.</p> */}
          <Evenements></Evenements>
        </section>
        <section className="section" id="je-seme">
          <div className="text">
            <h2>Je sème</h2>
            <p>
              Si vous désirez nous aider et soutenir l'action de Sénevé dans le
              Cotentin et au-delà, nous voulons vous donner la possibilité de
              donner financièrement.
            </p>
            <p>
              Chaque don nous permettra d'organiser de nouveaux événements et
              des rencontres pour louer Dieu ensemble.
            </p>
            <div className="bouton-block">
              <p>
                <a
                  className="bouton"
                  href="https://www.helloasso.com/associations/seneve/formulaires/1/widget"
                >
                  Donner
                </a>
              </p>
            </div>
          </div>
          <div className="img">
            <img src={JeSemeImg} alt="" />
          </div>
        </section>

        <section className="light-blue section" id="restons-connectes">
          <h2>Restons connectés</h2>
          <p>
            Nous nous retrouvons{" "}
            <em>
              <b>chaque dimanche</b>
            </em>{" "}
            de l'année scolaire{" "}
            <em>
              <b>à 10h30</b>
            </em>{" "}
            pour un culte protestant évangélique.
          </p>
          <div className="flex-conteneur">
            <div className="flex-gauche">
              <p>
                Contactez-nous pour toute{" "}
                <em>
                  <b>demande d'information</b>
                </em>{" "}
                sur nos rencontres, pour nous partager un{" "}
                <em>
                  <b>témoignage</b>
                </em>{" "}
                de l'action de Dieu dans votre vie ou pour demander la{" "}
                <em>
                  <b>prière</b>
                </em>{" "}
                :
              </p>
              <div className="bouton-block">
                <p>
                  <a className="bouton bleu" href="mailto:contact@seneve.org">
                    Nous contacter
                  </a>
                </p>
                <p>
                  <a className="bouton bleu" href="mailto:temoignage@seneve.org">
                    Témoigner
                  </a>
                </p>
                <p>
                  <a className="bouton bleu" href="mailto:priere@seneve.org">
                    Demander la prière
                  </a>
                </p>
              </div>
            </div>
            <div>
              <hr className="flex-separateur" />
            </div>
            <div className="flex-droite">
              <p>
                Vous pouvez aussi connecter avec l'église Sénevé par la{" "}
                <em>
                  <b>lettre de nouvelles</b>
                </em>{" "}
                et nous suivre sur nos{" "}
                <em>
                  <b>réseaux sociaux</b>
                </em>{" "}
                :
              </p>
              <div className="bouton-block">
                <p>
                  <a className="bouton bleu" href="https://newsletter.seneve.org">
                    <FaRegEnvelope />
                    &nbsp;Newsletter
                  </a>
                </p>
                <p>
                  <a
                    className="bouton bleu facebook"
                    href="https://www.facebook.com/seneve.org"
                  >
                    <FaFacebook />
                    &nbsp;Facebook
                  </a>
                </p>
                <p>
                  <a
                    className="bouton bleu instagram"
                    href="https://www.instagram.com/seneve_org/"
                  >
                    <FaInstagram />
                    &nbsp;Instagram
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <Mapbox></Mapbox>
      </main>
      <footer className="section">
        <div>
          <Titre></Titre>
        </div>
        {/* <hr /> */}
        <div>
          <p>Navigation :</p>
          <Navigation></Navigation>
        </div>
        {/* <hr /> */}
        <div>
          <p>Nous suivre :</p>
          <p>
            <a className="bouton clair" href="https://newsletter.seneve.org">
              <FaRegEnvelope />
              &nbsp;Newsletter
            </a>
          </p>
          <p>
            <a
              className="bouton clair facebook"
              href="https://www.facebook.com/seneve.org"
            >
              <FaFacebook />
              &nbsp;Facebook
            </a>
          </p>
          <p>
            <a
              className="bouton clair instagram"
              href="https://www.instagram.com/seneve_org/"
            >
              <FaInstagram />
              &nbsp;Instagram
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default IndexPage;
