import React /*, { useRef, useEffect, useState }*/ from "react";
// import { useStaticQuery, graphql } from "gatsby";
import FirebaseImg from "../images/firebase.jpg";
import AmenToiImg from "../images/amen-toi.jpeg";
import PHImg from "../images/ph-event.png";

const evenements = [
  // {
  //     titre: "Toujours un Autre",
  //     picture: FirebaseImg,
  //     date: "18 septembre 2021",
  //     url: "https://www.facebook.com/seneve.org/events",
  //     tags: [
  //         "priere",
  //         "louange",
  //         "unite"
  //     ]
  // },
  // {
  //     titre: "Toujours un Autre",
  //     picture: FirebaseImg,
  //     date: "18 septembre 2021",
  //     url: "https://www.facebook.com/seneve.org/events",
  //     tags: [
  //         "priere",
  //         "louange",
  //         "unite"
  //     ]
  // },
  // {
  //     titre: "Toujours un Autre",
  //     picture: FirebaseImg,
  //     date: "18 septembre 2021",
  //     url: "https://www.facebook.com/seneve.org/events",
  //     tags: [
  //         "priere",
  //         "louange",
  //         "unite"
  //     ]
  // },
  // {
  //     titre: "Toujours un Autre",
  //     picture: FirebaseImg,
  //     date: "18 septembre 2021",
  //     url: "https://www.facebook.com/seneve.org/events",
  //     tags: [
  //         "priere",
  //         "louange",
  //         "unite"
  //     ]
  // },
  // {
  //     titre: "Toujours un Autre",
  //     picture: FirebaseImg,
  //     date: "18 septembre 2021",
  //     url: "https://www.facebook.com/seneve.org/events",
  //     tags: [
  //         "priere",
  //         "louange",
  //         "unite"
  //     ]
  // },
  // {
  //     titre: "Encore un Autre",
  //     picture: FirebaseImg,
  //     date: "18 septembre 2021",
  //     url: "https://www.facebook.com/seneve.org/events",
  //     tags: [
  //         "priere",
  //         "louange",
  //         "unite"
  //     ]
  // },
  {
    titre: "Firebase",
    picture: FirebaseImg,
    date: "18 septembre 2021",
    url: "https://www.facebook.com/seneve.org/events",
    tags: ["priere", "louange", "unite"],
  },
  {
    titre: "Amen Toi",
    picture: AmenToiImg,
    date: "3 Juillet 2021",
    url: "https://www.facebook.com/seneve.org/events",
    tags: ["priere", "louange", "unite"],
  },
];

const Evenements = () => {
  // const [content, setContent] = useState('');
  // useEffect(() => {
  //     window.onload = function() {
  //         window.fbAsyncInit = function () {
  //             window.FB.init({
  //                 appId: '237929593888388',
  //                 autoLogAppEvents: true,
  //                 xfbml: true,
  //                 version: 'v11.0'
  //             });
  //             window.FB.api(
  //                 '/seneve.org',
  //                 'GET',
  //                 { "fields": "events", "access_token": "EAADYZAUzs3oQBAOROUY8dNetUFtYmytDxS9uV7oBlYCTBxa5PooAPjuzXMzj4qUqYZByNhU2FPa7jiDdWbQnDFlLHeLatxlU4JRpJCF7pPdbFIhsohNiygXCpBMHkjAPqnMZCJ938RsEkRdAX7OWrr8017PSqZCWQpiZAtacyfmFMsr8wp1URnE62sz6dfONIT2VmobXehQZDZD" },
  //                 function (response) {
  //                     alert(response);
  //                     setContent(response);
  //                 }
  //             );
  //         };
  //     };
  // });
  //     const query = graphql`
  //   {
  //     allFacebook {
  //       edges {
  //         node {
  //           id
  //           events {
  //             data {
  //               description
  //               start_time
  //               name
  //               id
  //               end_time
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `
  //     const data = useStaticQuery(query);

  return (
    <div className="evenements-conteneur">
      {evenements.map((evenement) => (
        <div className="event-card">
          <div
            className="img"
            style={{ backgroundImage: `url(${evenement.picture})` }}
          >
            <img src={PHImg} alt="" />
          </div>
          <h3>{evenement.titre}</h3>
          <p>
            <em>{evenement.date}</em>
          </p>
          <div className="bouton-block">
            <p>
              <a className="bouton bleu" href={evenement.url}>
                plus
              </a>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Evenements;
