import React, { useRef, useEffect, useState } from "react";

const Navigation = ({ sticky }) => {
  return (
    <nav className={sticky ? "sticky" : null}>
      <a className="h2" href="/">
        <h2>
          <span>Sénevé&nbsp;</span>
          <span role="img" aria-label="church emoji">
            ⛪️
          </span>
        </h2>
      </a>
      <ol>
        <li>
          <a href="/">Accueil</a>
        </li>
        <li>
          <a href="#evenements">Événements</a>
        </li>
        <li>
          <a href="#je-seme">Je sème</a>
        </li>
        <li>
          <a href="#restons-connectes">Restons connectés</a>
        </li>
      </ol>
    </nav>
  );
};

export default Navigation;
